import _get from "lodash/get";
import React from "react";
import Balance from "../../../components/numbers/balance";
import { LastBusinessDate } from "../../../components/numbers/last-business-date";
import Percent from "../../../components/numbers/percent";
import TableV2 from "../../../components/table/tableV2";
import { InvestmentV2 } from "../../../models/investment-v2";
import {
  aggregatedInvestmentSelector,
  selectedInvestmentsSelector,
} from "../../../selectors/investments";
import { hidePrrSelector } from "../../../selectors/plan-info";
import { aggregatedSingleDayInvestmentSelector } from "../../../selectors/single-day-investments";
import { useSelector, RootState } from "../../../store/store";
import { calculateTotalPRR } from "src/util/investments";

type CellInfo = {
  value?: any;
  type: any;
};

type Data = Array<{
  rowName: string;
  lastBusinessDay: {
    value: number | string | undefined;
    type: string;
  };
  ytd: {
    value: number | string | undefined;
    type: string;
  };
}>;

export default function AccountDetailsTable() {
  const hidePrr = useSelector(hidePrrSelector);
  const ytdPrr = calculateTotalPRR(useSelector(selectedInvestmentsSelector));
  const singleDayPrr = calculateTotalPRR(
    useSelector((state: RootState) => state.singleDayInvestments.data)
  );
  const ytdInvestment: InvestmentV2 = useSelector(aggregatedInvestmentSelector);
  const singleDayInvestment: InvestmentV2 = useSelector(
    aggregatedSingleDayInvestmentSelector
  );

  const columns = [
    {
      Header: <div className={"bold"}>Account Information</div>,
      accessor: "rowName",
      Footer: "",
    },
    {
      Header: (
        <div className={"bold"}>
          Activity on <LastBusinessDate />
        </div>
      ),
      accessor: "lastBusinessDay",
      Cell: (props: CellInfo) => {
        const { type, value } = props.value;

        if (type === "balance") {
          return <Balance value={value} showDollar={true} />;
        }

        return <Percent value={value} />;
      },
      className: "right",
      Footer: "",
    },
    {
      Header: <div className={"bold"}>Activity on Year-to-date</div>,
      accessor: "ytd",
      Cell: (props: CellInfo) => {
        const { type, value } = props.value;

        if (value === "N/A") {
          return <>{value}</>;
        }

        if (type === "balance") {
          return <Balance value={value} showDollar={true} />;
        }

        return <Percent value={value} />;
      },
      className: "right",
    },
  ];

  const data: Data = [
    {
      rowName: "Beginning balance",
      lastBusinessDay: {
        value: singleDayInvestment?.beginBalance?.cashBalance.balance,
        type: "balance",
      },
      ytd: {
        value: ytdInvestment.beginBalance?.cashBalance.balance,
        type: "balance",
      },
    },
    {
      rowName: "Additions",
      lastBusinessDay: {
        value: singleDayInvestment?.contributionAmt,
        type: "balance",
      },
      ytd: {
        value: ytdInvestment.contributionAmt,
        type: "balance",
      },
    },
    {
      rowName: "Distributions",
      lastBusinessDay: {
        value: singleDayInvestment?.distributionAmt,
        type: "balance",
      },
      ytd: {
        value: ytdInvestment.distributionAmt,
        type: "balance",
      },
    },
    {
      rowName: "Gain/Loss",
      lastBusinessDay: {
        value: calculateGainLoss(singleDayInvestment!),
        type: "balance",
      },
      ytd: {
        value: calculateGainLoss(ytdInvestment),
        type: "balance",
      },
    },
    {
      rowName: "Current balance",
      lastBusinessDay: {
        value: singleDayInvestment?.endBalance?.cashBalance.balance,
        type: "balance",
      },
      ytd: {
        value: ytdInvestment.endBalance?.cashBalance.balance,
        type: "balance",
      },
    },
    {
      rowName: "Vested balance",
      lastBusinessDay: {
        value: singleDayInvestment?.endBalance?.cashBalance.vestedBalance,
        type: "balance",
      },
      ytd: {
        value: ytdInvestment.endBalance?.cashBalance.vestedBalance,
        type: "balance",
      },
    },
  ];

  if (!hidePrr) {
    data.push({
      rowName: "Personalized rate of return",
      lastBusinessDay: {
        value: singleDayInvestment?.endBalance?.cashBalance.balance
          ? singleDayPrr
          : "N/A",
        type: "percent",
      },
      ytd: {
        value: singleDayInvestment?.endBalance?.cashBalance.balance
          ? ytdPrr
          : "N/A",
        type: "percent",
      },
    });
  }

  return <TableV2 columns={columns} data={data} />;
}

export function calculateGainLoss(investment: InvestmentV2): number {
  const end = Math.abs(_get(investment, "endBalance.cashBalance.balance", 0));
  const begin = Math.abs(
    _get(investment, "beginBalance.cashBalance.balance", 0)
  );
  const distributions = Math.abs(_get(investment, "distributionAmt", 0));
  const additions = Math.abs(_get(investment, "contributionAmt", 0));
  const changeInBalance = end - begin;
  const addedToAccount = additions - distributions;
  return changeInBalance - addedToAccount;
}
